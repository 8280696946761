

export default function VideoBanner() {


    return (

        <video id="banner" width="100%" autoPlay muted height="auto" style={{ marginBottom: '-6px' }}>
            <source src="https://portaldaregularizacao.seduh.df.gov.br/video/bannerportal.mp4" type="video/mp4" />
        </video>
    )
}