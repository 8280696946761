'use client';
import '../css/footer.css';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Footer() {

    return (
        // <Container fluid>
        <div className="footer" style={{ display: 'block' }}>



            <ul className="flex-container ">
                <div className='dive'>
                    <ul>
                        <li className='dives'><a className='linkA' href="http://www.empreender.df.gov.br/" target="_blank" rel="external"  >A SEDET</a></li>
                        <li className='dives'><a className='linkA' href="http://www.empreender.df.gov.br/" target="_blank" rel="external"  >Serviços</a></li>
                        <li className='dives'><a className='linkA' href="http://www.empreender.df.gov.br/" target="_blank" rel="external"  >Desenvolvimento Econômico</a></li>
                        <li className='dives'><a className='linkA' href="http://www.empreender.df.gov.br/" target="_blank" rel="external"  >Trabalho Emprego e Renda</a></li>
                        <li className='dives'><a className='linkA' href="http://www.empreender.df.gov.br/" target="_blank" rel="external"  >Licitações, Contratos e Parcerias</a></li>
                        <li className='dives'><a className='linkA' href="http://www.empreender.df.gov.br/" target="_blank" rel="external"  >MROSC</a></li>
                        <li className='dives'><a className='linkA' href="http://www.empreender.df.gov.br/" target="_blank" rel="external"  >Ouvidoria</a></li>

                    </ul>
                </div>
                <div className='dive'>
                    <ul>
                        <li className='dives'><a className='linkA' href="http://www.empreender.df.gov.br/" target="_blank" rel="external"  >Fale com a Secretaria</a></li>
                        <li className='dives'><a className='linkA' href="http://www.empreender.df.gov.br/" target="_blank" rel="external"  >Acesso à informação</a></li>
                        <li className='dives'><a className='linkA' href="http://www.empreender.df.gov.br/" target="_blank" rel="external"  >Ouvidoria</a></li>


                    </ul>
                </div>

                <div className='dive'>
                    <ul>
                        <li className='dives'><a className='linkA' href="http://www.empreender.df.gov.br/" target="_blank" rel="external"  >Acesse o Portal do Governo do Distrito Federal</a></li>
                        <li className='dives'><a className='linkA' href="http://www.empreender.df.gov.br/" target="_blank" rel="external"  >Setor de Edifícios de Utilidade Pública Norte – SEPN, Quadra 511, Bloco A, Asa Norte, Brasília-DF, CEP:70750-541</a></li>


                    </ul>
                </div>
                <div className='dive'>
                    <a href="http://www.empreender.df.gov.br/index.php/category/acesso-a-informacao/" target="_blank" rel="external">

                        <img src="http://www.empreender.df.gov.br/wp-conteudo/themes/site-secretarias/img/ico-acesso-info.svg" alt="" />
                    </a>
                </div>

                {/* <li class="flex-item">5</li> */}
            </ul>





        </div >
        // </Container>
    )
}
