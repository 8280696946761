let allLote = "";
const Service = {
    getADERA: async function () {

        if (localStorage.getItem("adera")) {
            return JSON.parse(localStorage.getItem("adera"));
        }

        const response = await fetch('/api/info/ra_ade');
        const json = await response.json();

        localStorage.setItem("adera", JSON.stringify(json));

        return json;
    },
    getLotes: async function () {
        if (allLote.length > 0) {
            return allLote;
        }
        const response = await fetch('/api/processoProdf/lote');
        allLote = await response.json();
        return allLote;
    },

    getLoteByRaId: async function (id) {
        const lista = await Service.getLotes();
        const itens = lista.filter(item => item.ra.endsWith(id));
        return itens;
    },
    getLoteById: async function (id) {
        const lista = await Service.getLotes();
        const item = lista.find(item => item.id === id);
        return item;
    },

    getADERAById: async function (id) {
        const adera = await Service.getADERA();
        return adera.find(item => item.nome.endsWith(id))
    },
    POST: function (url, dado, headers) {
        const val = {
            url: url,
            dado: dado
        }
        return fetch(url, {
            method: "POST",
            body: JSON.stringify(dado),
            headers: headers ? headers : {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                // Authorization: `Bearer ${localStorage.accessToken}`
            },
            // credentials: 'include'
        }).then(r => {
            if (r.ok) {
                return r.json();
            } else {
                return r.json().then(erro => {
                    // console.error(erro);
                });
            }
        });
    },
    GET: function (url) {
        return fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                // Authorization: `Bearer ${localStorage.accessToken}`
            }
        }).then(r => {
            if (r.ok) {
                return r.json();
            } else {
                return r.json().then(erro => {
                    console.error("Erro da requisicao: ", erro);
                    throw erro;

                });
            }
        });
    },
}

export default Service;