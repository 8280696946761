import '../css/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import '../css/header.css'
export default function Header() {



    return (
        <div className='head' height="15%">
            <div className='left'>

                <a href="http://www.transparencia.df.gov.br/" target="_blank" className='link'>Transparência</a>
                <a href="http://www.ouvidoria.df.gov.br/" target="_blank" className='link'>Ouvidoria</a>
                <a href="http://www.df.gov.br/category/acesso-a-informacao/" target="_blank" className='link'>Acesso à Informação</a>
                <a href="http://www.dodf.df.gov.br/" target="_blank" className='link'>Diário Oficial</a>
                <a href="https://www.agenciabrasilia.df.gov.br/" target="_blank" className='link'>Agência Brasília</a>
                <a href="http://www.brasilia.df.gov.br/" target="_blank" className='link'>Portal do Governo de Brasília</a>
                <a href="http://www.dados.df.gov.br/" target="_blank" className='link'>Dados Abertos</a>

            </div>
            <div edge="end" className='right'>
                <a href="http://brasilia.df.gov.br/index.php/acessibilidade/" className='link'>Acessibilidade</a>
                <span className='separaAncoras'>|</span>
                <a className='linkA' style={{color: '#ccc'}}>A
                    <span style={{color: '#ccc'}}>a</span>
                </a>
                <span className='separaAncoras'>|</span>
                <a href="http://www.vlibras.gov.br/" target="_blank" className='link'>
                    <img src="http://www.empreender.df.gov.br/wp-conteudo/themes/site-secretarias/img/ico-vlibras.svg" alt="Vlibras" />
                </a>

            </div>
        </div>
    )
}