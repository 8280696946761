import '../css/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function BarraHeader() {

    return (
        <div className="barra-header p-3">
            <div className="container-fluid padding-off">
                <div className="row d-flex m-3">
                    <div className="col-sm-12 col-lg-6 d-flex title-home-container ml-3 d-flex justify-content-center">
                        <div className="img-title">
                            <a href="/">
                                <img src="/SEDET.png" />
                            </a>
                        </div>
                        <div className="title-home-div">
                            <a href="/" className="title-home">Portal <br /><strong
                                style={{fontWeight: 600}}>Regularização</strong></a>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-6 hyper-link bar-links" style={{justifyContent: 'start'}}>
                        <div className="parcelamento-div">
                            <a className="" href="/mapa-regularizacao">Regularização</a>
                        </div>
                        {/* <div className="noticia-div">
                            <a className="" target="_blank"
                                href="http://www.seduh.df.gov.br/category/noticias/">Notícias</a>
                        </div>
                        <div className="fale-conosco-div">
                            <a className="fale-conosco" href="http://portaldaregularizacao.seduh.df.gov.br/fale-conosco">Fale Conosco</a>
                        </div> */}
                    </div>
                    <div className="div-none mt-3">
                        <a className="fale-conosco" href="http://portaldaregularizacao.seduh.df.gov.br/fale-conosco">Fale Conosco</a>
                    </div>
                </div>
            </div>
        </div>
    )
}