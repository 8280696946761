import * as React from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import Service from '../util/Service';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import Alert from 'react-bootstrap/Alert';
import Etapa from '../components/Etapa';
import '../css/style.css'
export default function InfoLotePage() {
    const { id } = useParams();
    const [lote, setLote] = React.useState();
    const [gerenciarProcesso, setGerenciarProcesso] = React.useState();
    const [processo, setProcesso] = React.useState();
    
    const navigate = useNavigate();

    React.useEffect(() => {
        const fetchData = async () => {

            const lt = await Service.getLoteById(id);
            setLote(lt);
            let pro = lt.processo;
            if (!pro) return;

            Service.POST('/api/gerenciarProcesso/findProcesso', pro).then(json => {
                setGerenciarProcesso(json);
                buscarProcesso(json);
            }).catch(error => {
                console.error(error);
            });
        }
        fetchData()
            .catch(console.error);
    }, [])
    const buscarProcesso = (pro)=>{
        if( !pro ) return;
        Service.GET('/api/gerenciarProcesso/'+ pro.idProcesso).then(json => {

            if( json.processo) setProcesso(json.processo);
        }).catch(error => {
            console.error(error);
        });
    }
    
    const MakeLoteComp = () => {
        if (!lote) {
            return (<Alert variant='warning'>
                Lote não encontrado
            </Alert>)
        }
        let comp = [];
        let index = 0;
        for (let x in lote) {
            if (x === 'id') continue;
            const valor = lote[x];
            if (!valor) continue;
            if (typeof valor === 'object') continue;
            comp.push(
                <div key={'id_' + index++} className="bg-light border"><b>{x}</b>: {valor}</div>
            )
        }
        return comp;
    }
    const MakeProcessoComp = () => {
        if (!gerenciarProcesso) {
            return (<Alert variant='warning'>
                Lote sem processo
            </Alert>)
        }
        let comp = [];
        let index = 0;
        for (let x in gerenciarProcesso) {
            if (x === 'id') continue;
            const valor = gerenciarProcesso[x];
            if (!valor) continue;
            if (typeof valor === 'object') continue;
            comp.push(
                <div key={'id_' + index++} className="bg-light border"><b>{x}</b>: {valor}</div>
            )
        }
        return comp;
    }
    const clickVoltar = () => {
        navigate(-1);
    }
    return (
        <Container style={{ marginTop: 20, marginBottom: 20 }}>
            <Row>
                <div className="d-flex align-items-center" style={{marginBottom:20}}>
                    <i onClick={clickVoltar} >
                        <FontAwesomeIcon icon={faArrowLeft} className="voltar" />
                    </i>
                    <div id="info-header" className=" w-100">
                        <h3 className="text-center">{lote && lote.endereco}</h3>
                    </div>
                </div>

            </Row>
            <Row>
                <Col >
                    <Etapa processo={processo}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h2>Informações Lote</h2>
                    <Stack gap={1}>
                        <MakeLoteComp />
                    </Stack>
                </Col>
                <Col>
                    <h2>Informações Processo</h2>
                    <Stack gap={1}>
                        <MakeProcessoComp />
                    </Stack>
                </Col>
            </Row>
            

        </Container>

    )
}