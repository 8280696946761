'use client';
import { createBrowserRouter } from "react-router-dom";
import { Outlet, Link } from "react-router-dom";
import ErrorPage from "../page/ErrorPage";
import Header from "./Header";
import Footer from "./Footer";
import HomePage from "../page/HomePage";
import MapaPage from "../page/MapaPage";
import ListaProcessosLote from "../page/ListaProcessosLote";
import InfoLotePage from "../page/InfoLotePage";
import BarraHeader from "./BarraHeader";

const PaginaEmBranco = () => {
    return (
        <p>pagina em branco, pagina precisa ser cadastrada</p>
    )
}
const Root = () => {
    return (
        <div id="error-page">
            <Header />
            <BarraHeader />
            <div >
                <Outlet />
            </div>
            <Footer />
        </div>
    )
}
const RouterConfig = createBrowserRouter([

    {
        path: "/",
        element: <Root />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "/",
                element: <HomePage />,
            },
            {
                path: "/mapa-regularizacao",
                element: <MapaPage />,
            },
            {
                path: "/lista-ra/:ra",
                element: <ListaProcessosLote />,
            },
            {
                path: "/info-lote/:id",
                element: <InfoLotePage />,
            },
            
        ],
    },
    {
        path: "/about",
        element: <div>About</div>,
    },
    {
        path: "/*",
        element: <div className="center_screen">ROTA NÃO CONFIGURADA</div>,
    },

])

export default RouterConfig;