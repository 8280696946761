import * as React from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/style.css';
import Table from 'react-bootstrap/Table';
import Service from '../util/Service';
import Alert from 'react-bootstrap/Alert';

const pageSize = 10;
let elapseTime = 0;
let tempo = 600;
let filtroCampo = null;

export default function ListaProcessosLote() {

    const { ra } = useParams();
    const [adera, setAdera] = React.useState();
    const navigate = useNavigate();

    const [lotes, setLotes] = React.useState();
    const [lotesFiltrado, setLotesFiltrado] = React.useState();
    const [lotesPagina, setLotesPagina] = React.useState();
    const [page, setPage] = React.useState(0);
    const [pageOfPage, setPageOfPage] = React.useState(0);
    const [txt, setTxt] = React.useState();

    React.useEffect(() => {
        filtroCampo = null;
        const fetchData = async () => {
            const roma = " "+arabicToRoman(ra);
            const ret = await Service.getADERAById(roma);
            setAdera(ret);
            const lst = await Service.getLoteByRaId(roma);
            setLotes(lst);
            setLotesFiltrado(lst);
        }
        fetchData()
            .catch(console.error);

    }, [])
    React.useEffect(() => {
        if (lotesFiltrado) {
            montaPagina(lotesFiltrado)
        }

    }, [lotesFiltrado, page])

    const montaFiltro = (lst) => {
        if (filtroCampo) {
            let arr = [];
            for (let x in lst) {
                const item = lst[x];

                if (item.proposta && item.proposta.includes(filtroCampo)) {
                    arr.push(item)
                } else if (item.processo && item.processo.includes(filtroCampo)) {
                    arr.push(item)
                } else if (item.endereco && item.endereco.toUpperCase().includes(filtroCampo.toUpperCase())) {
                    arr.push(item)
                }

            }
            montaPagina(arr);
            setLotesFiltrado(arr);
        } else {
            montaPagina(lst);
        }
        
    }
    function disparaSearch() {
        setTimeout(function () {
            if (performance.now() - elapseTime > tempo) {
                setPage(0);
                montaFiltro(lotes);
            } else {
                disparaSearch();
            }
        }, tempo);
    }
    const montaPagina = (lst) => {
        let arr = [];
        if (lst.length > 0) {
            for (let i = 0; i < pageSize; i++) {
                if (i + (page *pageSize)>= lst.length) {
                    break;
                }
                arr.push(lst[i + (page * pageSize)])
            }
        }
        setLotesPagina(arr);

    }

    const clickVoltar = () => {
        navigate(-1);
    }

    function arabicToRoman(num) {
        const romanNumeralMap = {
            M: 1000,
            CM: 900,
            D: 500,
            CD: 400,
            C: 100,
            XC: 90,
            L: 50,
            XL: 40,
            X: 10,
            IX: 9,
            V: 5,
            IV: 4,
            I: 1
        };
        let result = "";
        for (const key in romanNumeralMap) {
            const value = romanNumeralMap[key];
            while (num >= value) {
                result += key;
                num -= value;
            }
        }
        return result;
    }
    const handleSearch = (e) => {
        filtroCampo = e.target.value;
        if (performance.now() - elapseTime > tempo) {
            disparaSearch();
        }
        elapseTime = performance.now();
        setTxt(e.target.value);
    }
    const MontaPaginacao = () => {
        if (!lotesFiltrado) return (<p />)
        
        let compo = [];
        const size = lotesFiltrado.length / pageSize ;
        for (let i = 0; i < size; i++) {
            let currentPage = i+(pageOfPage*pageSize);
            if( currentPage >= size) break;
            if (i >= 10) {
                compo.push(
                    <li key={i} className="paginate_button"><a href="#" 
                        onClick={() => {
                            setPage(pageSize + page) 
                            setPageOfPage( pageOfPage+1)
                        }} aria-controls="tb-info" data-dt-idx="2" tabIndex="0">...</a></li>
                );
                break;
            }
            let el = page === (currentPage) ?
                <li key={i} className="paginate_button active"><a href="#" aria-controls="tb-info" data-dt-idx="1" tabIndex="0">{currentPage + 1}</a></li>
                :
                <li key={i} className="paginate_button"><a href="#" onClick={() => setPage(currentPage)} aria-controls="tb-info" data-dt-idx="2" tabIndex="0">{currentPage + 1}</a></li>
                ;
            compo.push(el);
        }

        return compo;
    }
    const handleCollClick =(item)=>{
        navigate("/info-lote/"+ item.id)
    }
    return (
        <div >

            <div className='btnVoltar'>
                <i id="voltar" onClick={clickVoltar} >
                    <FontAwesomeIcon icon={faArrowLeft} className="voltar" />
                </i>
                <div id="info-header" className=" w-100">
                    <h1 className="text-center"> {adera ? adera.nome : 'buscando...'} </h1>
                </div>
            </div>

            <div className="accordion m-3" id="accordionExample">

                <div id="tb-info-wrapper" className='search-fild'>
                    <div className='top row'>
                        <div id="tb-info_filter" className="dataTables_filter">
                            <label>Pesquisar<input type="search" value={txt ? txt : ''} onChange={handleSearch} className="form-control input-sm" placeholder="" aria-controls="tb-info" /></label>
                        </div>
                    </div>
                </div>



                <div id="collapseTwo" className="collapse show" aria-labelledby="headingTwo" data-parent="#accordionExample">
                    <div className="row justify-content-md-center no-margin">

                        <div id="loader"></div>



                        <div id="info-boty" className="col-md-10 tb-info-div">
                            <Table id="tb-info" striped bordered hover className="display table table-striped  nowrap" style={{ width: "100%" }}>
                                <thead>
                                    <tr>
                                        <th>Processo</th>
                                        <th>ADE</th>
                                        <th>Endereço</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(!lotesPagina || lotesPagina.length === 0) &&
                                        <tr role="row">
                                            <td colSpan={3}>
                                                <Alert variant='warning'>
                                                    Não existe lote para a RA - { arabicToRoman(ra) }
                                                </Alert>
                                            </td>
                                        </tr>
                                    }
                                    {(lotesPagina && lotesPagina.length > 0) && lotesPagina.map((item, i) =>
                                        <tr role="row" className="odd" key={i} onClick={ () =>handleCollClick(item)}>
                                            <td>{item.processo}</td>
                                            <td>{item.ade}</td>
                                            <td>{item.endereco}</td>
                                        </tr>
                                    )}

                                </tbody>
                            </Table>
                        </div>
                        <div className="bot" style={{ marginBottom: '15px' }}>
                            <div className="dataTables_paginate paging_simple_numbers" id="tb-info_paginate">
                                <ul className="pagination">

                                    <li className="paginate_button previous disabled" id="tb-info_previous"><a onClick={() => {
                                        if( page === 0) return;
                                        setPage(page - 1);
                                        if( (page - 1) < pageOfPage * pageSize){
                                            setPageOfPage( pageOfPage-1);
                                        }
                                    }} aria-controls="tb-info" data-dt-idx="0" tabIndex="0">Anterior</a></li>
                                    <MontaPaginacao />
                                    {/* <li className="paginate_button active"><a href="#" aria-controls="tb-info" data-dt-idx="1" tabIndex="0">1</a></li>
                                    <li className="paginate_button"><a href="#" aria-controls="tb-info" data-dt-idx="2" tabIndex="0">2</a></li>
                                    <li className="paginate_button"><a href="#" aria-controls="tb-info" data-dt-idx="3" tabIndex="0">3</a></li>
                                    <li className="paginate_button"><a href="#" aria-controls="tb-info" data-dt-idx="4" tabIndex="0">4</a></li>
                                    <li className="paginate_button"><a href="#" aria-controls="tb-info" data-dt-idx="5" tabIndex="0">5</a></li>
                                    <li className="paginate_button"><a href="#" aria-controls="tb-info" data-dt-idx="6" tabIndex="0">6</a></li>
                                    <li className="paginate_button"><a href="#" aria-controls="tb-info" data-dt-idx="7" tabIndex="0">7</a></li> */}
                                    <li className="paginate_button next" id="tb-info_next"><a onClick={() => 
                                        {
                                            setPage(page + 1)
                                            
                                            if( (page +1) >= ((1+pageOfPage) * pageSize)){
                                                setPageOfPage( pageOfPage+1);
                                            }
                                        }} aria-controls="tb-info" data-dt-idx="8" tabIndex="0">Próximo</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}