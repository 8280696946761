import * as React from 'react';
import MapaRA from '../components/MapaRA';
import '../components/mapa.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import BarraHeader from '../components/BarraHeader';
import MapaLeaflet from '../components/MapaLeaflet';

export default function MapaPage() {

    const [disableSat, setDisableSat] = React.useState(false);
    const [edit, setEdit] = React.useState(false);

    return (
        <div className='fundo-mapa'>
            {/* <div id="ico-menu-E" />
            <div id="lateral-E" style={{ marginTop: '100px', height: '792px' }}   >
                <div id="menu-E">
                    <h3>ModuloEdit</h3>
                    <div className="box-legenda no-select div-edit" onClick={() => setEdit(!edit)}>Edição {edit ? 'on' : 'off'}</div>
                    <h3>Camadas</h3>
                    <div className="box-legenda no-select div-ra" onClick={() => setDisableSat(!disableSat)}>{disableSat ? 'Habilita' : 'Remove'} Satélite</div>
                    {/* <div className="box-legenda no-select div-ra">RA</div>
                    <div className="box-legenda no-select div-arine">ARINE</div>
                    <div className="box-legenda no-select div-aris">ARIS</div>
                    <div className="box-legenda no-select div-pui-arine">PUI ARINE</div>
                    <div className="box-legenda no-select div-pui-aris">PUI ARIS</div> */}{/* 
                </div>
            </div> */}
            {/* <MapaRA disableSat={disableSat} edit={edit} /> */}
            <MapaLeaflet edit={edit}/>
        </div>
    )
}