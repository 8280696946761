import { RouterProvider} from "react-router-dom";

import logo from './logo.svg';
import RouterConfig from "./components/RouterConfig";
import './App.css';

function App() {
  return (
    <div className="App">
      <RouterProvider router={RouterConfig} />
    </div>
  );
}

export default App;
