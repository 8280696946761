import * as React from 'react';
import {
    MapContainer, TileLayer, Tooltip, Polygon, useMapEvents
    , GeoJSON
} from 'react-leaflet'
import { useNavigate } from 'react-router-dom';
import RAMapa from '../util/RAMapa';
import 'leaflet/dist/leaflet.css';
import seg from "./seg.json";

const fillBlueOptions = { fillColor: 'blue' }
const blackOptions = { color: 'black' }
const limeOptions = { color: 'lime' }
const purpleOptions = { color: 'blue' }
const redOptions = { color: 'red' }

let arrCoords = [];

const styleShow={
    color: '#4a83ec',
    weight: 0.5
}
const styleOver={
    color: '#4a83ec',
    weight: 2
}
const geo = {
    "type": "FeatureCollection", "features": [
        {
            "type": "Feature", "id": "01", "properties": { "name": "Alabama", "density": 94.65 }, "geometry": {
                "type": "Polygon", "coordinates": [[
                    [-47.79477905765737, -15.706383937245535],
                    [-47.798211307148506, -15.784393588101521],
                    [-47.72338826824174, -15.786376493662397],
                    [-47.719956018750594, -15.715640884340901],
                    [-47.79340615786092, -15.702416545439418]
                    // [-87.359296,35.00118],[-85.606675,34.984749],[-85.431413,34.124869],[-85.184951,32.859696],[-85.069935,32.580372],[-84.960397,32.421541],[-85.004212,32.322956],[-84.889196,32.262709],[-85.058981,32.13674],[-85.053504,32.01077],[-85.141136,31.840985],[-85.042551,31.539753],[-85.113751,31.27686],[-85.004212,31.003013],[-85.497137,30.997536],[-87.600282,30.997536],[-87.633143,30.86609],[-87.408589,30.674397],[-87.446927,30.510088],[-87.37025,30.427934],[-87.518128,30.280057],[-87.655051,30.247195],[-87.90699,30.411504],[-87.934375,30.657966],[-88.011052,30.685351],[-88.10416,30.499135],[-88.137022,30.318396],[-88.394438,30.367688],[-88.471115,31.895754],[-88.241084,33.796253],[-88.098683,34.891641],[-88.202745,34.995703],[-87.359296,35.00118]
                ]]
            }
        },
    ]
}


export default function MapaLeaflet(props) {
    const { edit } = props;
    const [shape, setShape] = React.useState();
    const [workItem, setWorkItem] = React.useState();
    const navigate = useNavigate();
    React.useEffect(() => {
        if (arrCoords && arrCoords.length > 0) {
            let s = '';
            for (let x in arrCoords) {
                s += '[' + arrCoords[x][1] + ", " + arrCoords[x][0] + '],';
            }
            console.log(s);
            arrCoords = [];
        }
        if (edit === false) {
            arrCoords = [];
        }
    }, [edit])


    function isIn(polygon, mouseX, mouseY) {
        let c = false;
        for (let i = 1, j = 0; i < polygon.length; i++, j++) {
            const ix = polygon[i][0];
            const iy = polygon[i][1];
            const jx = polygon[j][0];
            const jy = polygon[j][1];
            const iySide = (iy > mouseY);
            const jySide = (jy > mouseY);

            if (iySide != jySide) {
                const intersectX = (jx - ix) * (mouseY - iy) / (jy - iy) + ix;
                if (mouseX < intersectX)
                    c = !c;
            }
        }
        return c;
    }
    const LocationFinderDummy = () => {
        const map = useMapEvents({
            click(e) {
                if (edit === true) {
                    arrCoords.push([e.latlng.lat, e.latlng.lng])
                    setShape([...arrCoords]);

                } else {
                    arrCoords.push('[' + e.latlng.lat + ', ' + e.latlng.lng + ']');
                   
                    if (workItem) {
                        navigate("/lista-ra/" + workItem.id)
                    }
                }
            },
            // mousemove(e) {
            //     if (edit === true) return;
            //     for (let i = 0; i < RAMapa.RAs.length; i++) {
            //         const coord = RAMapa.RAs[i].coords
            //         if (typeof coord === 'object') {
            //             if (isIn(coord, e.latlng.lat, e.latlng.lng)) {
            //                 setShape(coord);
            //                 setWorkItem(RAMapa.RAs[i]);
            //                 break;
            //             } else {
            //                 setShape(null);
            //             }
            //         }
            //     }
            //     console.log(">>>mousemove<<<", edit)
            // }
        });
        return null;
    };
    // function onMouseOver(e, layer) {
    //     e.target.setStyle(styleOver);
    //     // layer.closePopup();
    //     layer.openPopup();
    // }
    // function onMouseOut(e, layer) {
    //     if(e.originalEvent.relatedTarget && e.originalEvent.relatedTarget.innerText && e.originalEvent.relatedTarget.innerText.includes('Leaflet')){
    //         layer.closePopup();
    //     }
    //     e.target.setStyle(styleShow);
        
    // }
    function onClickHandle(e) {
        const item = e.sourceTarget.feature;
        navigate("/lista-ra/" + item.id)
        e.target.setStyle({
            weight: 7,
            color: 'rgba(0,0,0,0)'
        });
    }

    function onEachFeatureHandle(feature, layer) {
        if (feature.properties) {
            const opitions = {
                direction:"top"
            }
            layer.bindTooltip(feature.properties.name, opitions);
        }
        layer.on({
            // mouseover: (e)=>onMouseOver(e, layer),
            // mouseout: (e)=>onMouseOut(e, layer),
            click: onClickHandle
        });
    }
    return (
        <MapContainer center={[-15.7801, -47.9292]} zoom={10} scrollWheelZoom={false}
            style={{ width: "100%", height: "calc(100vh - 4rem)" }}
        >
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            >

            </TileLayer>
            
            {shape &&
                <Polygon pathOptions={purpleOptions} positions={shape} >
                    {/* <Tooltip direction="bottom" offset={[0, 20]} opacity={1} permanent>
                        {workItem.nome}
                    </Tooltip> */}
                </Polygon>
            }
            {edit === false &&
                <GeoJSON data={seg}
                    style={styleShow}
                    onEachFeature={onEachFeatureHandle}
                >
                    {/* <Tooltip direction="bottom" offset={[0, 20]} opacity={1} permanent>
                        lalalal
                    </Tooltip> */}
                </GeoJSON>}

            <LocationFinderDummy />
        </MapContainer>

    )
}
