'use client';
import * as React from 'react';

import BarraHeader from "../components/BarraHeader"
import RegularizacaoBar from '../components/RegularizacaoBar';
import VideoBanner from '../components/VideoBanner';
import Conteudo from '../components/Conteudo';
import Cartilhas from '../components/Cartilhas';
import '../css/style.css'
//
export default function HomePage() {
  return (
    <div >
      
      {/* <BarraHeader /> */}
      <RegularizacaoBar />
      <VideoBanner />
      <Conteudo />
      {/* <Cartilhas /> */}
    
    </div>
  )
}
