import * as React from 'react';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Service from '../util/Service';
const arrEtapas=['Processo', 'Imóvel', 'Carta Consulta', 'Indicação de Área', 'Projeto', 'Contrato Terrapca'
, 'Início de Implatação', 'Prorrogação Contrato', 'Atestado', 'Escrituração', 'Declaração de cumprimento de metas']
const corEtapa=[
    '#D8CC01', '#018AD8', '#01D869', '#8701D8', 'Salmon', '#ffbf00', '#00bfff', '#ff00ff', '#936c6c', '#7C37A6', '#A69437'
]
const corNaoAplica = '#808080';

export default function Etapa(props) {
    const { processo } = props;
    const [etapa, setEtapa] = React.useState();

    React.useEffect(() => {
        if (processo) {
            buscarEtapas(processo.dadosEmpresa.nProcesso)
        }
    }, [processo])

    const buscarEtapas=(nProcesso)=>{
        const dado = {
            nProcesso: nProcesso
        }

        Service.POST('/api/processoProdf/etapa/nProcesso', dado).then(json => {
            if( json && json.listaEtapa){
                let arr = json.listaEtapa.sort( (a,b) => a.index - b.index)
                setEtapa( arr );
            }
        }).catch(error => {
           console.error( error);
        });
    }

    const MakeItem = (etapa, sub, status) => {
        let index = parseInt(etapa)-1;
        let cor = index < corEtapa.length? corEtapa[index]:'#0000FF';
        let imgCard = "/etapa/nConcluido.png"
        if( status === '' || status === 'nsa') cor = corNaoAplica;
        if( status === 'concluido') imgCard = "/etapa/concluido.png";
        return (
            <Card key={etapa} style={{ width: '130px', marginLeft: 0, background: cor }}>
                <Card.Img src={imgCard} alt="Card image" />
                <Card.ImgOverlay>
                    <Card.Body>
                        <Card.Title className='header-card'><b>{etapa}</b></Card.Title>

                        <Card.Subtitle className='subtext-card' style={{ marginLeft: '-25px' }}>
                            {sub}
                        </Card.Subtitle>

                    </Card.Body>

                    {/* <div className='text-card' style={{ marginTop: '20px', marginLeft:'-16px', width:'100%' }}><b>{status}</b></div> */}


                </Card.ImgOverlay>
            </Card>
        )
    }
    const MontaEtapas = () => {
        if (!processo) return <p>....</p>;
        // if (!etapa) return <p>buscando etapa</p>;

        let comp = [];
        for(let i=0; i< arrEtapas.length; i++){
            let index = (i + 1) < 10 ? '0' + (i + 1) : i + 1;
            
            if(etapa){
                const item = etapa.find( e => e.indice === (i+1));
                comp.push( MakeItem(index, arrEtapas[i], item?item.situacao:''));
            }else{
                comp.push( MakeItem(index, arrEtapas[i], ''));
            }
            
        }
        return comp;
    }

    return (
        <Stack direction="horizontal">
            {processo && <MontaEtapas />}

        </Stack>

    )
    
}