import '../css/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';


export default function RegularizacaoBar() {


    return (
        <div className="regularizacao-bar">
            <div className="container d-flex justify-content-end align-items-center p-2 regularizacao-div">
                <div className="d-flex justify-content-between align-items-center text-regularizacao-2">
                    <a href="https://novosparcelamentos.seduh.df.gov.br/" target="_blank" className="img-regularizcao text-decoration-none">
                        {/* <p className="text-white m-0 ir-para-site">
                            <img src="http://portaldaregularizacao.seduh.df.gov.br/img/logo.png" />
                            Ir para Novos Parcelamentos do Solo
                        </p> */}
                    </a>
                </div>
            </div>
        </div>
    )
}